<template>
    <div class="payments">
        <div class="payments__header">
            <div class="payments__title payments__title_main">Тариф</div>
            <div class="payments__name" v-if="!$store.getters.SELECTED_USER.isFree && $store.getters.SELECTED_USER.payAmount !== 0">
                <p>Платный</p>
                <div class="payments__count">{{$store.getters.SELECTED_USER.payAmount || 0}}р.</div>
            </div>
            <div class="payments__name" v-else>
                <p>Бесплатный</p>
            </div>
        </div>
        <div class="payments__body">
            <div class="payments__title payments__title_body">Чек:</div>
            <ul class="payments__list">
                <li class="payments__item">
                    <span class="payments__title">Кол-во камер</span>
                    <div class="payments__data payments__data_count">
                        {{getCountCameras}}
                    </div>
                </li>
                <li class="payments__item">
                    <span class="payments__title">Видео</span>
                    <div class="payments__data payments__data_video">
                        {{getVideoCameras}}
                    </div>
                </li>
                <li class="payments__item">
                    <span class="payments__title">Аудио</span>
                    <div class="payments__data payments__data_count">
                        {{getAudioCameras}}
                    </div>
                </li>
                <li class="payments__item">
                    <span class="payments__title">Архив</span>
                    <div class="payments__data payments__data_archive">
                        {{getArchiveDays}}
                    </div>
                </li>
            </ul>
        </div>
        <div class="payments__footer">
            <div class="payments__form">
                <p>Назначеная стоимость</p>
                <input class="payments__input" @focus="changeFocus" v-model="pay" type="text">
            </div>
            <div class="payments__button">
                <button :disabled="pay.length === 0 && !isFocus" @click="sendPayment">Изменить</button>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "Payments",
        data() {
            return {
                isFocus: false,
                pay: ''
            }
        },
        computed: {
            getCountCameras() {
                const cameras = this.$store.getters.SELECTED_USER.cameras
                if (cameras && cameras.length) {
                    return cameras.length
                }
                else if (this.$store.getters.SELECTED_USER.childUsers.length) {
                    let counter = 0
                    for (const user of this.$store.getters.SELECTED_USER.childUsers) {
                        counter+=user.camerasCount
                    }
                    return counter
                }
                return 0
            },
            getVideoCameras() {
                let videoArray = []
                let cameras = this.$store.getters.SELECTED_USER.cameras
                if (!cameras.length && this.$store.getters.SELECTED_USER.childUsers.length) {
                    cameras = []
                    for (const user of this.$store.getters.SELECTED_USER.childUsers) {
                        for (const camera of user.cameras) {
                            cameras.push(camera)
                        }
                    }
                }
                if (cameras && cameras.length) {
                    for (let camera of cameras) {
                        let cameraName = undefined
                        switch (camera.qualityType) {
                            case 0 : {
                                cameraName = 'SD'
                                break
                            }
                            case 1: {
                                cameraName = 'HD'
                                break
                            }
                            case 2: {
                                cameraName = 'FHD'
                                break
                            }
                        }
                        if (cameraName && videoArray.indexOf(cameraName) === -1) {
                            videoArray.push(cameraName)
                        }
                    }
                }
                return videoArray.join(',')
            },
            getAudioCameras() {
                let audioArray = []
                let cameras = this.$store.getters.SELECTED_USER.cameras
                if (!cameras.length && this.$store.getters.SELECTED_USER.childUsers.length) {
                    cameras = []
                    for (const user of this.$store.getters.SELECTED_USER.childUsers) {
                        for (const camera of user.cameras) {
                            cameras.push(camera)
                        }
                    }
                }
                if (cameras && cameras.length) {
                    for (let camera of cameras) {
                        if (audioArray.indexOf(camera.isAudio) === -1) {
                            audioArray.push(camera.isAudio)
                        }
                    }
                }
                if (!audioArray.length) {
                    return 'Остуствует'
                } else if (audioArray.length > 1) {
                    return 'Mix'
                } else {
                    return audioArray[0] ? 'Есть' : 'Остуствует'
                }
            },
            getArchiveDays() {
                let archive = []
                let cameras = this.$store.getters.SELECTED_USER.cameras
                if (cameras && cameras.length) {
                    for (let camera of cameras) {
                        archive.push(camera.archiveDaysCount)
                    }
                }
                if (!cameras.length && this.$store.getters.SELECTED_USER.childUsers.length) {
                    cameras = []
                    for (const user of this.$store.getters.SELECTED_USER.childUsers) {
                        for (const camera of user.cameras) {
                            archive.push(camera.archiveDaysCount)
                        }
                    }
                }
                if (archive.length) {
                    const min = _.min(archive)
                    const max = _.max(archive)
                    if (min !== max) {
                        return `${min}-${max} дней`
                    } else {
                        return `${min} дней`
                    }
                } else {
                    return 'Без записи'
                }
            },
            getAmountToCamera() {
                return this.$store.getters.SELECTED_USER.payAmount
            }
        },
        methods: {
            changeFocus () {
                if (!this.pay.length) {
                 this.isFocus = true
                }
            },
            sendPayment() {
                this.$store.dispatch('CHANGE_PAYMENTS', {
                    userId: this.$route.params.id, data: {payAmount: this.pay}
                })
                this.pay = ''
                this.isFocus = false
            }
        },
        watch: {
            pay() {
                if (this.pay.length) {
                    this.pay = this.pay.replace(/[^0-9]/gim, '')
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    .payments {
        padding: 0 22px;
        font-family: Roboto, sans-serif;
        line-height: 1.5;
        //max-width: 180px;
        &__header {
            padding: 22px 0 11px;
            border-bottom: 1px solid #E4E4E4;
        }

        &__title {
            color: #999999;
            font-size: 14px;

            &_body {
                color: #2C2C2C;
            }
        }

        &__name {
            padding-top: 9px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                color: #2C2C2C;
                font-size: 14px;
            }
        }

        &__count {
            color: #6F64F8;
            font-size: 14px;
        }

        &__body {
            padding-top: 15px;
            padding-bottom: 16px;
        }

        &__list {
            padding: 9px 0 0;
        }

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 4px;

            &:last-child {
                padding-bottom: 0;
            }
        }

        &__data {
            font-size: 14px;

            &_count {
                color: #999999;
            }

            &_video {
                color: #2DCD7A;
            }

            &_archive {
                color: #6F64F8;
            }
        }

        &__footer {
            border-top: 1px solid #E4E4E4;
            padding-top: 15px;
        }

        &__form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //flex-direction: column;
            @include for-desktop-up{}

            p {
                font-size: 14px;
                color: #2C2C2C;
                //padding-bottom: 10px;
            }
        }

        &__input {
            background: #FFFFFF;
            border: 0.5px solid rgba(151, 151, 151, 0.24186);
            box-shadow: inset 0px 1px 3px 0.25px rgba(0, 0, 0, 0.101371);
            border-radius: 4px;
            width: 82px;
            height: 30px;
            line-height: 30px;
            outline: none;
            //padding: 0 25px;
            text-align: center;
            color: #2C2C2C;
            font-size: 14px;
        }

        &__button {
            padding-top: 30px;
            padding-bottom: 20px;
            button {
                width: 100%;
                background: #6F64F8;
                border-radius: 8px;
                outline: none;
                border: none;
                height: 45px;
                line-height: 40px;
                color: #fff;
                cursor: pointer;
                transition: all .25s;
                font-size: 14px;
                text-transform: uppercase;

                &:disabled {
                    opacity: 0.42;
                }
            }
        }
    }
</style>